<template>
    <div class="dataBaseCenter-slider">
        <div class="new-module" @click="handleAddGroup('first')">
            <span class="iconfont iconxianshi_tianjia"></span>
            <span>新建目录</span>
        </div>
        <div class="tree-con">
            <el-tree
                :data="treeData"
                :props="defaultProps"
                :highlight-current="true"
                :expand-on-click-node="false"
                node-key="id"
                :default-expanded-keys="defaultKey"
                :current-node-key="currentNodeKey"
                @node-contextmenu="rightClick"
                class="businessGroupWh-slider-tree"
                ref="tree"
            >
                <span class="slot-t-node" slot-scope="{ node, data }">
                    <div class="parent-node" v-if="data.directory_type===0">
                        <i v-if="node.expanded" class="iconfont iconshouqi" @click.stop="expandtree(node)"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai" @click.stop="expandtree(node)"></i>
                        <span v-if="node.expanded" class="iconfont iconwenjianjia-zhankai" @click.stop="expandtree(node)"></span>
                        <span v-if="!node.expanded" class="iconfont iconwenjianjia-shouqi" @click.stop="expandtree(node)"></span>
                        <span>{{ data.name }}</span>
                    </div>
                    <div class="leaf-node" v-else @click="loadTable(data.entity_id)">
                        <span class="iconfont iconlingxing"></span>
                        <span>{{ data.name }}</span>
                    </div>
                </span>
            </el-tree>
        </div>

        <div class="more-menu" v-show="menuVisible" id="menu">
            <el-menu>
                <el-menu-item @click="handleAddGroup('other')" v-if="currentNode.directory_type===0">
                    <span class="iconfont icontianjia1"></span>
                    <span>添加目录</span>
                </el-menu-item>
                <el-menu-item @click="handleEditGroup">
                    <span class="iconfont iconbianji1"></span>
                    <span>修改目录</span>
                </el-menu-item>
                <el-menu-item @click="handleDelNode">
                    <span class="iconfont iconshanchu"></span>
                    <span>删除目录</span>
                </el-menu-item>
            </el-menu>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            defaultKey: [], // 默认展开节点id集合
            currentNodeKey: '', // 当前节点key
            menuVisible: false,
            currentNode: {}, // 当前节点数据
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 展开节点
        expandtree(node) {
            node.expanded = !node.expanded;
            if (node.expanded) {
                this.defaultKey = [];
                this.defaultKey.push(node.data.id);
            } else {
                this.defaultKey = [];
            }
        },
        // 右键菜单
        rightClick(MouseEvent, data, node) {
            this.defaultKey = [];
            this.defaultKey.push(node.data.id);
            this.currentNode = data;
            this.menuVisible = false; // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
            this.menuVisible = true; // 显示模态窗口，跳出自定义菜单栏
            this.$forceUpdate();
            const menu = document.querySelector('#menu');
            document.addEventListener('click', this.foo); // 给整个document添加监听鼠标事件，点击任何位置执行foo方法
            menu.style.left = MouseEvent.clientX + 0 + 'px';
            menu.style.top = MouseEvent.clientY + 10 + 'px';
        },
        foo() { // 取消鼠标监听事件 菜单栏
            this.menuVisible = false;
            document.removeEventListener('click', this.foo); // 要及时关掉监听
        },
        // 拉取业务分组树
        getTree() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/directory/gettree?parent_code=0')
                .then(res => {
                    this.treeData = res;
                    const obj = this.getFirstLeafNode(res);
                    this.$emit('load-data', obj.entity_id);
                    this.defaultKey = [];
                    this.defaultKey.push(obj.id);
                    this.currentNodeKey = obj.id;
                    this.$nextTick(() => {
                        this.$refs.tree.setCurrentKey(obj.id);
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 递归查找第一个叶子节点
        getFirstLeafNode(data) {
            let obj = null;
            if (data && data.length > 0) {
                const item = data[0];
                if (item.children.length === 0) {
                    obj = item;
                } else {
                    obj = this.getFirstLeafNode(item.children);
                }
            }
            return obj;
        },
        // 新建业务模块
        handleAddGroup(type) {
            let parent_id = '';
            if (type !== 'first') {
                parent_id = this.currentNode.id;
            }
            this.$toast(
                {
                    title: true,
                    text: '新增',
                    type: 'eject',
                    width: '6rem',
                    height: '4rem',
                    t_url: 'dataBaseCenter/catalogManage/addGroup',
                    viewPosition: 'view',
                    extr: { closeBtn: {}, handleType: 1, parent_id: parent_id, callBackFn: this.handleCallBack },
                });
        },
        // 修改业务模块
        handleEditGroup() {
            const data = JSON.parse(JSON.stringify(this.currentNode));
            const currentNode = {
                id: data.id,
                name: data.name,
                directory_type: data.directory_type,
                remarks: data.remarks,
                sort_num: data.sort_num,
            };
            this.$toast(
                {
                    title: true,
                    text: '编辑',
                    type: 'eject',
                    width: '6rem',
                    height: '4rem',
                    t_url: 'dataBaseCenter/catalogManage/addGroup',
                    viewPosition: 'view',
                    extr: { closeBtn: {}, handleType: 2, node: currentNode, callBackFn: this.handleCallBack },
                });
        },
        // 删除节点
        handleDelNode() {
            if (this.currentNode.children.length > 0) {
                this.$message.warning('有子节点，不能删除');
                return;
            }
            this.$axios
                .delete('/interfaceApi/datacenter/metadata/directory/' + this.currentNode.id)
                .then(res => {
                    if (res) {
                        this.$message.success('删除成功');
                        this.handleCallBack();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 新增完成更新树
        handleCallBack() {
            this.getTree();
        },
        // 加载表格
        loadTable(id) {
            this.currentNodeKey = id;
            this.$emit('load-data', id);
        },
    },
    created() {
        this.getTree();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.dataBaseCenter-slider
    width 2.2rem
    height 100%
    background #fff
    overflow hidden
    float left
    .new-module
        height .44rem
        background #F8F8F8
        border-bottom 1px solid #D7D7D7
        line-height .44rem
        color #333
        cursor pointer
        .iconxianshi_tianjia
            margin 0 .09rem 0 .12rem
        &:hover
            color #5588F1
    .tree-con
        height calc(100% - .54rem)
        overflow-y auto
        overflow-x auto
        .slot-t-node
            line-height .38rem
            height .38rem
            .parent-node
                .iconzhankai
                    color #5588F1
                .iconshouqi
                    color #F19F26
                .iconwenjianjia-shouqi
                    color #5588F1
                .iconwenjianjia-zhankai
                    color #F19F26
                .iconfont
                    margin-right .1rem
            .leaf-node
                color #666666
                .iconlingxing
                    color #979EA7
                    margin 0 .1rem 0 0
                    font-size .14rem
    .more-menu
        width 1.7rem
        position absolute
        border 1px solid #d3d5d4
        box-shadow 0 0 .05rem #d3d5d4
        border-bottom none
        z-index 1000
        .iconfont
            margin-right .1rem
        .menu-name
            background #f8f8f8
        .el-menu
            border-right none
            .el-menu-item
                border-bottom 1px solid #f0f0f0
                height .38rem
                line-height .38rem
                &:focus
                    background-color #1577d2
                    color #fff
        .header
            background #5588F1
            color #fff
            text-align center
            font-size .16rem
            line-height .32rem!important
</style>
<style>
.dataBaseCenter-slider .el-icon-caret-right:before{
    display: none!important;
}
.dataBaseCenter-slider .el-tree-node__content{
    height: .36rem;
}
.dataBaseCenter-slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node{
    color: #FE7903;
}
.dataBaseCenter-slider .tree-con .is-current>.el-tree-node__content .slot-t-node .leaf-node .iconlingxing{
    color: #FE7903;
}
.dataBaseCenter-slider .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color:#E2EAF0;
}

/* 树形结构节点添加连线 */
/* .businessGroupWh-slider-tree /deep/ .el-tree-node {
    position: relative;
    padding-left: .16rem;
}

.businessGroupWh-slider-tree /deep/ .el-tree-node__children {
    padding-left: .16rem;
}

.businessGroupWh-slider-tree /deep/ .el-tree-node :last-child:before {
    height: .38rem;
}

.businessGroupWh-slider-tree /deep/ .el-tree > .el-tree-node:before {
    border-left: none;
}

.businessGroupWh-slider-tree-con /deep/ .el-tree > .el-tree-node:after {
    border-top: none;
}

.businessGroupWh-slider-tree /deep/ .el-tree-node:before {
    content: "";
    left: -.04rem;
    position: absolute;
    right: auto;
    border-width: 1px;
}

.businessGroupWh-slider-tree /deep/ .el-tree-node:after {
    content: "";
    left: -.04rem               ;
    position: absolute;
    right: auto;
    border-width: 1px;
}

.businessGroupWh-slider-tree /deep/ .el-tree-node:before {
    border-left: 1px dashed #4386c6;
    bottom: 0px;
    height: 100%;
    top: -26px;
    width: 1px;
}

.businessGroupWh-slider-tree /deep/ .el-tree-node:after {
    border-top: 1px dashed #4386c6;
    height: 20px;
    top: 12px;
    width: 24px;
} */
</style>